$(document).ready(function () {
	var scroll = $(this).scrollTop();
	var windowsWidth = $(this).width();
	if (windowsWidth < 768) {
		$('.navbar').removeClass('navbar-dark').addClass('navbar-light');
		$('.navbar .navbar-brand img').attr('src', 'assets/images/logo-inverse.svg');
	} else {
		if (scroll > 100) {
			$('.navbar').removeClass('navbar-dark').addClass('navbar-light');
			$('.navbar .navbar-brand img').attr('src', 'assets/images/logo-inverse.svg');
		}
		else {
			$('.navbar').removeClass('navbar-light').addClass('navbar-dark');
			$('.navbar .navbar-brand img').attr('src', 'assets/images/logo-white.svg');
		}
	}

	var lastId,
		topMenu = $(".navbar"),
		AllLinkMenu = $("body"),
		topMenuHeight = topMenu.outerHeight() + 0,
		menuItems = AllLinkMenu.find("a.link-to"),
		navItems = topMenu.find("a"),
		scrollItems = navItems.map(function () {
			var item = $($(this).attr("href"));
			if (item.length) { return item; }
		});

	var fromTop = $(this).scrollTop() + topMenuHeight;
	var cur = scrollItems.map(function () {
		if ($(this).offset().top < fromTop)
			return this;
	});
	cur = cur[cur.length - 1];
	var id = cur && cur.length ? cur[0].id : "";

	if (lastId !== id) {
		lastId = id;
		navItems
			.parent().removeClass("active")
			.end().filter("[href='#" + id + "']").parent().addClass("active");
	}

	menuItems.click(function (e) {
		var href = $(this).attr("href"),
			offsetTop = href === "#" ? 0 : $(href).offset().top - topMenuHeight + 1;
		$('html, body').stop().animate({
			scrollTop: offsetTop
		}, 700);
		e.preventDefault();
	});

	$(window).scroll(function () {
		scroll = $(this).scrollTop();
		windowsWidth = $(this).width();
		if (windowsWidth < 768) {
			$('.navbar').removeClass('navbar-dark').addClass('navbar-light');
			$('.navbar .navbar-brand img').attr('src', 'assets/images/logo-inverse.svg');
		} else {
			if (scroll > 100) {
				$('.navbar').removeClass('navbar-dark').addClass('navbar-light');
				$('.navbar .navbar-brand img').attr('src', 'assets/images/logo-inverse.svg');
			}
			else {
				$('.navbar').removeClass('navbar-light').addClass('navbar-dark');
				$('.navbar .navbar-brand img').attr('src', 'assets/images/logo-white.svg');
			}
		}

		var fromTop = $(this).scrollTop() + topMenuHeight;
		var cur = scrollItems.map(function () {
			if ($(this).offset().top < fromTop)
				return this;
		});
		cur = cur[cur.length - 1];
		var id = cur && cur.length ? cur[0].id : "";

		if (lastId !== id) {
			lastId = id;
			navItems
				.parent().removeClass("active")
				.end().filter("[href='#" + id + "']").parent().addClass("active");
		}
	});
});